<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <b-alert variant="info" show dismissible>
      {{ $t('event_registrierung.anmeldung.alert_global_login') }}
    </b-alert>
    <b-form @submit.prevent="handleSubmit(login)">
      <ValidationProvider
        :name="$t('event_registrierung.anmeldung.label_username')"
        rules="required"
        v-slot="validationContext"
      >
        <b-form-group
          id="fieldset-username"
          :label="$t('event_registrierung.anmeldung.label_username') + ' *'"
          label-for="input-username"
        >
          <b-form-input
            id="input-username"
            v-model="form.username"
            size="sm"
            :state="getValidationState(validationContext)"
            aria-describedby="feedback-username"
          ></b-form-input>
          <b-form-invalid-feedback id="feedback-username">{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        :name="$t('event_registrierung.anmeldung.label_password')"
        rules="required"
        v-slot="validationContext"
      >
        <b-form-group
          id="fieldset-password"
          :label="$t('event_registrierung.anmeldung.label_password') + ' *'"
          label-for="input-password"
        >
          <b-form-input
            id="input-password"
            type="password"
            v-model="form.password"
            size="sm"
            :state="getValidationState(validationContext)"
            aria-describedby="feedback-password"
          ></b-form-input>
          <b-form-invalid-feedback id="feedback-password">{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="message.show" class="mb-0">
          <b-alert :variant="message.type ? message.type : 'info'" show>{{
            message.msg
          }}</b-alert>
        </b-form-group>
      </ValidationProvider>
      <b-form-group>
          <b-link :to="`/${$i18n.locale}/veranstalter/login/reset`">{{ $t('event_registrierung.anmeldung.reset_password') }}</b-link>
      </b-form-group>
      <b-button
        :to="`/${$i18n.locale}/veranstalter/event-erstellen`"
        variant="light"
        class="mr-2"
        >{{ $t('event_registrierung.anmeldung.back') }}</b-button
      >
      <b-button type="submit" variant="primary">{{ $t('event_registrierung.anmeldung.login') }}</b-button>
    </b-form>
    <div
      v-if="displayPageLoader"
      id="pageLoader"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </ValidationObserver>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      message: {
        show: false,
        type: "",
        msg: "",
      },
      displayPageLoader: false,
      debugLoginCorrect: true,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetFormMessage() {
      this.message.show = false;
      this.message.type = "";
      this.message.msg = "";
    },
    resetFormData() {
      this.form.username = "";
      this.form.password = "";
    },
    login() {
      this.resetFormMessage();
      this.displayPageLoader = true;
      // Sende formular daten an api
      this.$store
        .dispatch("veranstalter/login", this.form)
        .then(() => {
          this.message.show = true;
          this.message.type = "success";
          this.message.msg = this.$t(
            "event_registrierung.anmeldung.form_success"
          );
          
          // Redirect zur Veranstalter Seite
          this.$router.push(`/${this.$i18n.locale}/veranstalter/uebersicht`)
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status === 401) {
            this.message.show = true;
            this.message.type = "danger";
            this.message.msg = this.$t(
              "event_registrierung.anmeldung.form_unauthorized"
            );
          } else {
            this.message.show = true;
            this.message.type = "danger";
            this.message.msg = this.$t(
              "event_registrierung.anmeldung.form_fail"
            );
          }
        })
        .then(() => {
          this.displayPageLoader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#pageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(239, 239, 239, 0.6);
}
</style>